import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from "react-router-dom";
import { GlobalStyle as BaseGlobalStyle, Theme, themeOptions as baseThemeOptions } from '@commonsku/styles';
import { isAuthorized, isShopOpen, getPopups, getShopById, getGalleryOrderByParent, getFullCart } from '../selectors';

import { createCloseCart, createLoadShop } from '../actions/shop';

import Overlay from '../components/helpers/Overlay';
import ErrorBoundary from '../components/ErrorBoundary';
import PopupsOverlay from '../components/Overlay';
import Home from '../components/shop/Home';
import ShopGallery from '../components/shop/ShopGallery';
import Cart from '../components/shop/Cart';
import Checkout from '../components/shop/Checkout';
import Receipt from '../components/shop/Receipt';

import createPopup from '../popup-factory';
import { WebsocketContextProvider } from '../context/websocket-context';
import ShopChat from '../components/chat/ShopChat';
import { getNotificationUserList } from '../helpers/chat';
import ScrollToTop from '../components/ScrollToTop';
import { window } from '../global';
import { getWithExpiry } from '../local-storage-util';
import { SKUFontDefinitionStyles } from '../components/helpers/createGlobalStyle';
import { hexToHSL } from '../utils';
import { hideShopIntro, isShopPreviewing } from '../components/shop/helpers';
import { withRouter } from '../components/helpers';
import { getPublicViewTemplateDropdown } from '../selectors/dropdowns';
import { PublicViewTemplate } from '../models/PublicViewTemplate';
import { setFilter } from '../redux/gallery';
import CartProvider from '../context/CartProvider';
import PublicViewEntityProvider from '../context/PublicViewEntityProvider';
import PublicViewProvider from '../context/PublicViewProvider';
import { parseShopToPublicViewEntity } from '../hooks/usePublicViewEntity';
import { getDefaultPublicViewTemplateTheme } from '../components/shop/Storefront/helpers';
import PublicViewTemplateDataProvider from '../context/PublicViewTemplateDataProvider';

const DEFAULT_COLOR = '#5ca3b6';

const additionalGlobalStyle = (p) => `
${SKUFontDefinitionStyles}
.resku .gallery-root {
  ${p.galleryRootBg ? `background-color: ${p.galleryRootBg};` : ''}
  ${p.isCartOpen ? 'overflow: hidden !important;' : ''}
}
.select-contacts-popup-content .select-existing, .select-address-popup-content .select-existing {
  overflow-y: hidden !important;
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__control {
  border: none;
  border-color: unset;
  box-shadow: none;
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__control .commonsku-styles-select__value-container {
  padding-right: 0;
}

.shop .mobile-sidebar.slide-from-left {
  height: calc(100vh - 78px - env(safe-area-inset-bottom)) !important;
}

div.popup.intro-popup > div.intro-popup-content.popup-content {
  height: 100%;
}

#feat-prod.item-preview h4 {
  color: #404f59 !important;
}

.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__menu
  .commonsku-styles-select__option.commonsku-styles-select__option--is-selected {
  background: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90) .commonsku-styles-select__control .commonsku-styles-select__single-value {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90) .commonsku-styles-select__menu {
  border: 1px solid ${p.theme?.template_color || 'var(--color-primary)'};
  border-top: 1px solid ${p.theme?.template_color || 'var(--color-primary)'};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 1px 1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    -1px -1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    1px -1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    -1px 1px 0px ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90)
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator,
.shop .extra-categories-options .extra-categories-select:not(.neutrals-90)
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator > svg {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select.neutrals-90 .commonsku-styles-select__control .commonsku-styles-select__single-value {
  color: var(--color-neutrals-90);
}
.shop .extra-categories-options .extra-categories-select.active .commonsku-styles-select__control .commonsku-styles-select__single-value {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select.neutrals-90 .commonsku-styles-select__menu {
  border: 1px solid var(--color-neutrals-90);
  border-top: 1px solid var(--color-neutrals-90);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 1px 1px 0px var(--color-neutrals-90),
    -1px -1px 0px var(--color-neutrals-90),
    1px -1px 0px var(--color-neutrals-90),
    -1px 1px 0px var(--color-neutrals-90)};
}
.shop .extra-categories-options .extra-categories-select.neutrals-90
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator,
.shop .extra-categories-options .extra-categories-select.neutrals-90
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator > svg {
    color: var(--color-neutrals-90);
}

.resku #hero-banner .row .product-column.column:last-child:not(:first-child),
.resku #hero-banner .row .product-column.columns:last-child:not(:first-child) {
  float: left;
}

.popup.shop-item-popup .popup-content.shop-item-popup-content {
  height: 100%;
}

.shop .title-bar #shop-title h1.new-shop-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /*
  &:hover {
    white-space: normal;
    overflow: unset;
    text-overflow: unset;
  }
  */
}
`;

const GlobalStyle = (p) =>
  <BaseGlobalStyle {...p} additionalStyles={additionalGlobalStyle} />;

class PublicShopApp extends Component {
  themeOptions = baseThemeOptions;

  constructor(props) {
    super(props);

    this.state = {
      selected: props.params.item_id,
      params: props.params,
      contact: null,
      showCart: false,
      cartItemDetailsId: null,
      hideIntro: false,
      isPreviewing: false,
    };
    this.onClickCart = this.onClickCart.bind(this);
    this.handleMessageReceived = this.handleMessageReceived.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  getThemeOptions(template_color = DEFAULT_COLOR) {
    const hslColor = hexToHSL(template_color);
    const selectColors = baseThemeOptions.colors.select;
    const inputColors = baseThemeOptions.colors.input;
    return {
      ...baseThemeOptions,
      template_color,
      colors: {
        ...baseThemeOptions.colors,
        primary25: `hsl(${hslColor[0]}, 100%, 25%)`,
        primary75: `hsl(${hslColor[0]}, 100%, 25%)`,
        primary50: `hsl(${hslColor[0]}, 100%, 50%)`,
        primary: template_color,
        select: {
          border: template_color,
          ...selectColors,
          active: {
            ...selectColors.active,
            border: template_color,
          },
          hover: {
            ...selectColors.hover,
            border: template_color,
          },
          dropdownIcon: {
            ...selectColors.dropdownIcon,
            color: template_color,
          },
        },
        input: {
          ...inputColors,
          border: template_color,
          hover: {
            ...inputColors.hover,
            border: template_color,
          },
          active: {
            ...inputColors.active,
            border: template_color,
          },
          iconWrapper: {
            ...inputColors.iconWrapper,
            hover: {
              ...inputColors.iconWrapper.hover,
              background: `hsl(${hslColor[0]}, 100%, 25%)`,
            },
          },
          icon: {
            ...inputColors.icon,
            active: {
              ...inputColors.icon.active,
              fill: template_color,
            },
            hover: {
              ...inputColors.icon.hover,
              fill: template_color,
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    if (!this.state.contact) {
      const localStorageContact = getWithExpiry('client_contact');
      if (localStorageContact) {
          this.setState({
            contact: localStorageContact
          });
      }
    }
    // redirect to home if on cart page
    const cartMatch = matchPath({ path: '/shop/:shop_id/cart' }, window.location.pathname);
    if (this.props.panel === 'cart' || cartMatch) {
      const { shop } = this.props;
      Promise.resolve(this.props.onCloseCart()).then(() => {
        this.props.navigate(
          `/order/${shop.shop_id}/shop${+shop.buy_inventory === 1 ? '?buy_inventory=true' : ''}`
        );
      });
    }

    window.addEventListener('message', this.handleMessageReceived);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.params, this.props.params)
      || !isEqual(prevProps.location, this.props.location)
      || !isEqual(prevProps.panel, this.props.panel)
    ) {
      this.setState({
        selected: this.props.params?.item_id || null,
        params: this.props.params
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessageReceived);
  }

  onFilterChange(filter) {
    let destination, parsedFilter;
    switch (filter) {
      case 'ALL_PRODUCTS':
        destination = 'shop';
        parsedFilter = 'shop';
        break;
      case 'HOME':
        destination = '';
        parsedFilter = '';
        break;
      default:
        destination = 'shop';
        parsedFilter = filter;
        break;
    }
    (this.props.navigate ?? this.props.router.navigate)?.(`/shop/${this.props.params.shop_id}/${destination}`);
    this.props.setFilter(parsedFilter);
  }

  handleMessageReceived(event) {
    var origin = event.origin || event.originalEvent.origin;
    if (origin !== window.location.origin) {
      return;
    }

    if (typeof event.data === 'object' && event.data.call === 'sendShopValue') {
      this.setState({
        hideIntro: event.data.value.hideIntro,
        isPreviewing: event.data.value.isPreviewing,
      });
    } else if (typeof  event.data === 'object' && event.data.call === 'sendShopFilterChange') {
      const { filter } = event.data.value; // HOME, ALL_PRODUCTS, category_id
      this.onFilterChange(filter);

    }
  }

  onClickCart(value = null, cartItemDetailsId) {
    this.setState({ showCart: value === null ? !this.state.showCart : value, cartItemDetailsId });
  }

  getProductPageStyle() {
    const template_data = this.props.shop.template_data[
      this.props.shop.public_view_template_id
    ] || {};
    return (template_data.product_page_style || {value: 'CAROUSEL'}).value;
  }

  renderPanel(panel) {
    const { shop } = this.props;
    const { selected, params, contact, hideIntro, isPreviewing } = this.state;
    const productPageStyle = this.getProductPageStyle();
    const productInPopup = productPageStyle === 'POPUP';
    const productInDropdown = productPageStyle === 'DROPDOWN';
    const productInCarousel = productPageStyle === 'CAROUSEL';

    const commonProps = {
      shop: shop,
      params: params,
      contact: contact,
      onClickCart: this.onClickCart,
      showCart: this.state.showCart,
      isCartOpen: this.state.showCart,
      productInPopup: productInPopup,
      productInDropdown: productInDropdown,
      productInCarousel: productInCarousel,
      hideIntro: hideIntro || hideShopIntro() || this.props.params.hideHelp || this.props.params.hideIntro,
      isPreviewing: isPreviewing || isShopPreviewing() || this.props.params.previewShop,
      is_shop_free: shop.is_shop_free,
      buy_inventory: shop.buy_inventory ?? 0,
    };

    switch (panel) {
      case 'home':
        return <Home {...commonProps} />;
      case 'shop':
        return <ShopGallery selected={selected} {...commonProps} />;
      case 'cart':
        return <ShopGallery selected={selected} {...commonProps} />;
      case 'checkout':
        return <Checkout
          {...commonProps}
          // needed for (constructor of) checkout component
          entityId={shop.shop_id}
          entityType={'SHOP'}
          clientId={shop.client_id}
          aggregate={shop.aggregate}
          billingAddressType={shop.billing_address_type}
          shippingAddressType={shop.shipping_address_type}
          billingContactType={shop.billing_contact_type}
          shippingContactType={shop.shipping_contact_type}
        />;
      case 'receipt':
        return <Receipt {...commonProps} />;
    }
  }

  render() {
    const { authorized, panel, popups, userIdentity, notifyUser, tenantOptions, shop, public_view_template_name, publicViewTemplate, localQuestionData } = this.props;
    const chatChannel = window.location.href.split('/').slice(0, 5).join('/');
    if (tenantOptions) {
      tenantOptions.avatar = shop?.company_data?.avatar ?? '/images/favicons/favicon-96x96.png';
    }
    const productPageStyle = this.getProductPageStyle();
    const productInPopup = productPageStyle === 'POPUP';
    const productInDropdown = productPageStyle === 'DROPDOWN';
    const isPosterTheme = public_view_template_name === PublicViewTemplate.LEFT_NAV_HERO_IMAGE;
    const entityValue = parseShopToPublicViewEntity(shop, this.props.order);
    const styledThemeOptions = this.getThemeOptions(publicViewTemplate.templateData.template_color);

    return (
      <Theme theme={styledThemeOptions}>
        <GlobalStyle
          theme={styledThemeOptions}
          galleryRootBg={panel === 'checkout' || isPosterTheme ? '#fff' : ''}
          isCartOpen={this.state.showCart}
        />
        <ErrorBoundary>
          {!productInPopup && !productInDropdown && <ScrollToTop />}
          <WebsocketContextProvider
            identity={userIdentity}
            notifyUser={notifyUser}
            canVoiceCall={false}
            channel={chatChannel}
            tenantOptions={tenantOptions}
            tenantId={shop.tenant_id}
            defaultColor={publicViewTemplate.templateData.template_color || DEFAULT_COLOR}
            getBots={false}
          >
            <CartProvider entityId={shop.shop_id} isEntityFree={shop.is_shop_free}>
              <PublicViewTemplateDataProvider initialValue={publicViewTemplate}>
                <PublicViewEntityProvider initialValue={entityValue}>
                  <PublicViewProvider
                    cart={this.props.cart}
                    cartItemDetailsId={this.state.cartItemDetailsId}
                    contact={this.state.contact}
                    editingCartItemId={this.props.editingCartItemId}
                    filter={this.props.filter}
                    fullHeight={['checkout', 'receipt', 'cart'].includes(panel)}
                    onClickCart={this.onClickCart}
                    selectedItemId={this.props.selected}
                    showCart={this.state.showCart}
                  >
                    <div style={{ boxSizing: 'border-box' }}>
                      <Cart
                        cart={this.props.cart}
                        shop={shop}
                        params={this.state.params}
                        contact={this.state.contact}
                        showCart={this.state.showCart}
                        onClose={() => this.onClickCart(false)}
                        onClickCart={this.onClickCart}
                        fullHeight={['checkout', 'receipt', 'cart'].includes(panel)}
                        showDetailsItemId={this.state.cartItemDetailsId}
                        is_shop_free={shop.is_shop_free}
                        buy_inventory={shop.buy_inventory ?? 0}
                      />
                      {this.state.showCart && <Overlay />}
                      {authorized ?
                        this.renderPanel(panel)
                        : null}
                      {popups.map((p, idx) => createPopup(p, idx))}
                      <PopupsOverlay popups={popups} />
                      <ShopChat isShopProductPage={Boolean(this.props.selected)} />
                    </div>
                  </PublicViewProvider>
                </PublicViewEntityProvider>
              </PublicViewTemplateDataProvider>
            </CartProvider>
          </WebsocketContextProvider>
        </ErrorBoundary>
      </Theme>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const shop = getShopById(state, ownProps.params);
  const notifyOptions = state.entities.options ? state.entities.options.chat_notification_user : false;
  const public_view_templates_list = getPublicViewTemplateDropdown(state);
  const public_view_template_name = public_view_templates_list
    .find(st => st.public_view_template_id === shop.public_view_template_id)?.public_view_template_name || '';
  const publicViewTemplate = getDefaultPublicViewTemplateTheme(
    shop, public_view_templates_list
  );

  return {
    tenantOptions: state.entities.options,
    authorized: isAuthorized(state),
    open: isShopOpen(state),
    popups: getPopups(state),
    shop,
    selected: ownProps.params.item_id,
    notifyUser: getNotificationUserList(notifyOptions, shop.sales_rep_id, shop.client_rep_id),
    userIdentity: state.identity,
    public_view_template_name,
    cart: getFullCart(state, { buy_inventory: shop.buy_inventory }),
    order: getGalleryOrderByParent(state, { parent_type: 'SHOP', parent_id: shop.shop_id }),
    editingCartItemId: state.temp.editingCartItemId,
    filter: state.gallery.filter,
    public_view_templates_list,
    publicViewTemplate,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitPassword: (shop_id, password) => {
    //dispatch(createSpinnerPopup('Loading shop...'));
    return dispatch(createLoadShop(shop_id, password)).then((result) => {
      //dispatch(closePopup());
      return !!result;
    });
  },
  onCloseCart: (shop_id) => dispatch(createCloseCart(shop_id)),
  setFilter: (filter) => dispatch(setFilter(filter)),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...ownProps,
  ...propsFromState,
  onCloseCart: () => propsFromDispatch.onCloseCart(ownProps.params.shop_id),
  setFilter: (filter) => propsFromDispatch.setFilter(filter),
  popups: !propsFromState.open ? [{
    component: 'ClosedShopPopup',
  }].concat(propsFromState.popups) : propsFromState.authorized ? propsFromState.popups : [
    {
      component: 'PasswordPopup',
      title: propsFromState.shop.shop_name,
      placeholder: 'Enter shop password',
      submitColor: propsFromState.publicViewTemplate.templateData.template_color || DEFAULT_COLOR,
      onSubmit: password => propsFromDispatch.onSubmitPassword(ownProps.params.shop_id, password),
    }
  ].concat(propsFromState.popups)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PublicShopApp));
